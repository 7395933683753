<template src="./searchProduct.html"></template>

<script>
import productCard from '../productCard/productCard';
import productCardSearch from '../productCard/productCardSearch';
import header from '../header/header';

let CancelToken;
let source;

export default {
  name: 'productSubCategory',
  components: {
    productCardSearch,
    productCard,
    recommendationHeader: header,
  },
  data() {
    return {
      isNewAtStoreLoading: false,
      isLatestReviewedLoading: false,
      showSearchPopup: false,
      newAtStore: '',
      latestReviewed: '',
      globalSearch: [],
      searchLoading: false,
      productHightlight: '',
      isProductHighlightLoading: false,
    };
  },
  computed: {
    keyword() {
      return this.$route.query.q;
    },
  },
  watch: {
    '$route.query.q'(to, from) {
      this.getGlobalSearch();
    },
  },
  created() {
    this.getProductHightlight();
    this.getNewAtStore();
    this.getLatestReviewed();
    this.getGlobalSearch();
  },
  methods: {
    triggerHideSearchPopup() {
      const thisel = this;
      setTimeout(function () {
        thisel.showSearchPopup = false;
      }, 500);
    },
    triggerShowSearchPopup() {
      this.showSearchPopup = this.keyword.length > 3 ? true : false;
    },
    getNewAtStore() {
      this.isNewAtStoreLoading = true;
      this.$MS_SOCO_PUBLIC_API_URL
        .get(`/products/new-at-store`, {
          params: {
            limit: 10,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.newAtStore = response.data.data;
          } else {
            this.newAtStore = [];
          }
          this.isNewAtStoreLoading = false;
        })
        .catch((error) => {
          this.newAtStore = [];
          this.isNewAtStoreLoading = false;
        });
    },
    getLatestReviewed() {
      this.isLatestReviewedLoading = true;
      this.$MS_SOCO_PUBLIC_API_URL
        .get(`/products/latest-reviewed`, {
          params: {
            limit: 10,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.latestReviewed = response.data.data;
          } else {
            this.latestReviewed = [];
          }
          this.isLatestReviewedLoading = false;
        })
        .catch((error) => {
          this.latestReviewed = [];
          this.isLatestReviewedLoading = false;
        });
    },
    getGlobalSearch() {
      if (this.keyword.length > 3) {
        if (typeof source !== 'undefined') {
          source.cancel(false);
        }
        CancelToken = this.$MS_SOCO_PUBLIC_API_URL.CancelToken;
        source = CancelToken.source();

        this.globalSearch = '';
        this.searchLoading = true;

        this.$MS_SOCO_PUBLIC_API_URL
          .get(`/global-search`, {
            params: {
              filter: {
                keyword: this.keyword,
                types: ['add-review'],
                limit: 3,
                availability: true,
                is_offline: 1,
                sort: 'most-purchased',
              },
            },
            cancelToken: source.token,
          })
          .then((res) => {
            this.searchLoading = false;
            this.globalSearch = res.data.data['add-review'];
          });
      }
    },
    getCookies() {
      if (Cookies.get('store_location_id') && Cookies.get('store_location_id') != undefined) {
        return Cookies.get('store_location_id');
      }
    },
    getProductHightlight() {
      this.isProductHighlightLoading = true;
      this.$MS_SOCO_PUBLIC_API_URL
        .get(
          `/offline-store/store-cms/products?screen=SOCO%20interactive%20recommendation%20machine&store_location_id=${this.getCookies()}`
        )
        .then((response) => {
          if (response.status === 200 && response.data.data.length > 0) {
            this.productHightlight = response.data.data.shift().products;
          } else {
            this.productHightlight = [];
          }
          this.isProductHighlightLoading = false;
        })
        .catch((error) => {
          this.productHightlight = [];
          this.isProductHighlightLoading = false;
        });
    },
    mappingProduct(item) {
      item.manufacturer_name = item.brand;
      item.image_cover = item.product_image_url;
      item.price = item.original_price;
      return item;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'searchProduct';
.home-page {
  .welcome-text {
    font-family: 'lato-regular';
    font-size: 24px;
    color: #000000;
    line-height: 42px;
    text-align: center;
  }
  .back-btn {
    margin: 0px 25px 8px;
    a {
      background: #ffe4ed;
      padding: 12px 20px;
      font-family: 'lato-medium';
      font-size: 20px;
      color: #b32656;
      line-height: 27px;
      letter-spacing: 1.67px;
      border-radius: 30px;
      margin-right: 16px;
      text-transform: uppercase;
    }
  }
  .promotion-product {
    padding: 0px 24px 30px;

    .beauty-text {
      font-family: 'lato-medium';
      font-size: 24px;
      color: #354586;
      text-align: center;
    }
  }
  h6 {
    font-family: 'brandontext-bold';
    font-size: 24px;
    letter-spacing: 1.5px;
    color: #000000;
    text-transform: uppercase;
    padding: 0px 40px;
    margin: 48px 0px 0px;
  }
  .new-store {
    display: flex;
    position: relative;
    padding: 0px;
    width: 100%;
    overflow: scroll;
  }
}

@media (max-width: 900px) {
  .home-page .main-section .promotion-product {
    padding: 0px 24px 30px;
  }
  .home-page .main-section .promotion-product ul .col-md-4 {
    width: 33.33%;
    padding: 0px 10px;
  }
}
</style>
