var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{attrs:{"to":'/recommendation/product-details?sociolla_id=' + _vm.product.id_product + '&soco_id=' + _vm.product.id_product_soco}},[_c('li',{staticClass:"block-product"},[_c('figure',[_c('div',{staticClass:"imgwrap"},[_c('img',{attrs:{"src":_vm.getProductCoverImage,"alt":"cover"}})]),(_vm.product.discount_type == 'percentage')?_c('div',{staticClass:"offer-tag"},[_vm._v(_vm._s(_vm.product.discount)+"%")]):_vm._e()]),_c('div',{staticClass:"info"},[_c('span',{staticClass:"brands"},[_vm._v(_vm._s(_vm.product.manufacturer_name || _vm.product.manufacture.name))]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.product.product_name || _vm.product.name))])]),_c('div',{staticClass:"price"},[(
          (_vm.product.discount_type && _vm.product.price_after_discount < _vm.product.price) ||
          (_vm.product.discount_details &&
            _vm.product.discount_details.reduction_type &&
            _vm.product.price > _vm.product.price_after_discount)
        )?_c('span',{staticClass:"cut-price"},[_c('strike',[_vm._v("Rp "+_vm._s(_vm._f("currency")(_vm.product.price)))])],1):_vm._e(),(_vm.product.price_after_discount > 0 && _vm.product.discount_type == 'percentage')?_c('span',{staticClass:"save-price"},[_vm._v("SAVE "+_vm._s(_vm.product.discount)+"%")]):(_vm.product.discount_details && _vm.product.discount_details.reduction_type == 'percentage')?_c('span',{staticClass:"save-price"},[_vm._v("SAVE -"+_vm._s(_vm.product.discount_details.reduction * 100)+"%")]):(_vm.product.price_after_discount > 0 && _vm.product.discount_type == 'amount')?_c('span',{staticClass:"save-price"},[_vm._v("SAVE -Rp. "+_vm._s(_vm._f("currency")((_vm.product.price - _vm.product.price_after_discount))))]):(_vm.product.discount_details && _vm.product.discount_details.reduction_type == 'amount')?_c('span',{staticClass:"save-price"},[_vm._v("SAVE -Rp. "+_vm._s(_vm._f("currency")(_vm.product.discount_details.reduction)))]):_c('span',{staticClass:"save-price"}),(_vm.product.price_after_discount < _vm.product.price && _vm.product.price_after_discount && _vm.product.discount_type)?_c('span',{staticClass:"actual-price"},[_vm._v("Rp "+_vm._s(_vm._f("currency")(_vm.product.price_after_discount)))]):(
          _vm.product.price_after_discount < _vm.product.price &&
          _vm.product.discount_details &&
          _vm.product.discount_details.reduction_type
        )?_c('span',{staticClass:"actual-price"},[_vm._v("Rp "+_vm._s(_vm._f("currency")(_vm.product.price_after_discount)))]):_c('span',{staticClass:"actual-price"},[_vm._v("Rp "+_vm._s(_vm._f("currency")(_vm.product.price)))])]),_c('div',{staticClass:"rate"},[_c('div',{staticClass:"rs",class:'rs-' + _vm.$options.filters.rating(_vm.product.counter_review_rating)},[_c('span'),_c('span'),_c('span'),_c('span'),_c('span'),_c('div',{staticClass:"count"},[_vm._v("("+_vm._s(_vm.product.counter_review_user ? _vm.product.counter_review_user : '0')+")")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }